<template>
    <v-app>
        <v-dialog v-model="loading"
                  persistent
                  max-width="290">
            <div class="cssload-container">
                <div class="cssload-whirlpool"></div>
            </div>
        </v-dialog>
        <component :is="layout">
            <router-view/>
        </component>
    </v-app>
</template>

<script>
    const defaultLayout = "default";
    export default {
        computed: {
            layout() {
                return (this.$route.meta.layout || defaultLayout) + "-layout";
            },
            loading() {
                return this.$store.getters["auth/isLoading"];
            }
        }
    };
</script>

<style>
    .cssload-whirlpool,
    .cssload-whirlpool::before,
    .cssload-whirlpool::after {
        position: absolute;
        top: 50%;
        left: 50%;
        border: 1px solid rgb(63, 196, 79);
        border-left-color: rgb(0, 0, 0);
        border-radius: 1374px;
        -o-border-radius: 1374px;
        -ms-border-radius: 1374px;
        -webkit-border-radius: 1374px;
        -moz-border-radius: 1374px;
    }

    .cssload-whirlpool {
        margin: -34px 0 0 -34px;
        height: 69px;
        width: 69px;
        animation: cssload-rotate 1150ms linear infinite;
        -o-animation: cssload-rotate 1150ms linear infinite;
        -ms-animation: cssload-rotate 1150ms linear infinite;
        -webkit-animation: cssload-rotate 1150ms linear infinite;
        -moz-animation: cssload-rotate 1150ms linear infinite;
    }

    .cssload-whirlpool::before {
        content: "";
        margin: -32px 0 0 -32px;
        height: 61px;
        width: 61px;
        animation: cssload-rotate 1150ms linear infinite;
        -o-animation: cssload-rotate 1150ms linear infinite;
        -ms-animation: cssload-rotate 1150ms linear infinite;
        -webkit-animation: cssload-rotate 1150ms linear infinite;
        -moz-animation: cssload-rotate 1150ms linear infinite;
    }

    .cssload-whirlpool::after {
        content: "";
        margin: -40px 0 0 -40px;
        height: 77px;
        width: 77px;
        animation: cssload-rotate 2300ms linear infinite;
        -o-animation: cssload-rotate 2300ms linear infinite;
        -ms-animation: cssload-rotate 2300ms linear infinite;
        -webkit-animation: cssload-rotate 2300ms linear infinite;
        -moz-animation: cssload-rotate 2300ms linear infinite;
    }

    @keyframes cssload-rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    @-o-keyframes cssload-rotate {
        100% {
            -o-transform: rotate(360deg);
        }
    }

    @-ms-keyframes cssload-rotate {
        100% {
            -ms-transform: rotate(360deg);
        }
    }

    @-webkit-keyframes cssload-rotate {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @-moz-keyframes cssload-rotate {
        100% {
            -moz-transform: rotate(360deg);
        }
    }
</style>