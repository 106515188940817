import axios from 'axios'
import Vue from "vue";
import store from '../store'

const baseURL = process.env.VUE_APP_HOST;

let caxios = axios.create({
    baseURL: baseURL,
    withCredentials: false,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

caxios.baseURL = baseURL;

// Add a response interceptor
caxios.interceptors.response.use(
    response => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        store.commit('auth/loading', false, {root: true});
        return response;
    },
    error => {
        store.commit('auth/loading', false, {root: true});
        if (error.response && error.response.status && 401 === error.response.status) {
            window.location = '/login';
        } else {
            console.log(error.response);
            let text;
            if (error.response.data && error.response.data.error_info && error.response.data.error_info.message) {
                text = error.response.data.error_info.message
            } else if (error.response.data.message) {
                text = error.response.data.message
            } else if (error.response.data) {
                text = error.response.data
            } else {
                text = error.response.statusText
            }
            Vue.prototype.$alert(
                text,
                error.response.status ? error.response.status : "error",
                "error"
            );
        }

        return Promise.reject(error);
    });

// Add a request interceptor
caxios.interceptors.request.use(
    (r) => {
        store.commit('auth/loading', true, {root: true});
        return r;
    });

const token = localStorage.getItem('token');
if (token) {
    caxios.defaults.headers.common['Authorization'] = token
}

caxios.send = (props) => {
    return new Promise(((resolve, reject) => {
        caxios(props)
            .then(resp => {
                resolve(resp);
            })
            .catch(err => {
                reject(err);
            });
    }))
};

export default caxios;
