import axios from "@/services/axios";

export default {
    namespaced: true,
    strict: true,
    state: {
        items: [],
        storeItems: [],
        notified: [],
        order: {}
    },
    actions: {
        fetch({commit}, payload) {
            return new Promise((resolve) => {
                axios.send({
                    url: "/orders/" + payload,
                    method: "GET",
                }).then((resp) => {
                    commit("fetch", resp.data);
                    resolve();
                })
            })
        },
        fetchItems({commit}, payload) {
            return new Promise((resolve) => {
                axios.send({
                    url: "/orders/items/" + payload,
                    method: "GET",
                }).then((resp) => {
                    commit("fetchItems", resp.data);
                    resolve();
                })
            })
        },
        fetchStoreItems({commit}, payload) {
            return new Promise((resolve) => {
                axios.send({
                    url: "/orders/items/list/" + payload,
                    method: "GET",
                }).then((resp) => {
                    commit("fetchStoreItems", resp.data);
                    resolve();
                })
            })
        },
        fetchNotifiedDrivers({commit}, payload) {
            axios.send({
                url: "/orders/notified/" + payload,
                method: "GET",
            }).then((resp) => {
                commit("fetchNotifiedDrivers", resp.data);
            });
        }
    },
    mutations: {
        fetch(state, payload) {
            state.order = payload;
        },
        fetchItems(state, payload) {
            state.items = payload;
        },
        fetchStoreItems(state, payload) {
            state.storeItems = payload;
        },
        fetchNotifiedDrivers(state, payload) {
            state.notified = payload;
        },
    },
    getters: {
        order: state => {
            if (state.order) {
                return state.order
            } else {
                return [];
            }
        },
        items: state => {
            if (state.items) {
                return state.items
            } else {
                return [];
            }
        },
        storeItems: state => {
            if (state.storeItems) {
                return state.storeItems
            } else {
                return [];
            }
        },
        notified: state => {
            if (state.notified) {
                return state.notified
            } else {
                return [];
            }
        },
    }
}
