import axios from '../services/axios'

const auth = {
    namespaced: true,
    strict: true,
    state: {
        token: localStorage.getItem('token') || '',
        username: '',
        role: localStorage.getItem('role') || '',
        loading: false
    },
    actions: {
        login({commit}, credentials) {
            return new Promise((resolve, reject) => {
                axios.request({url: '/auth/login', data: credentials, method: 'POST'})
                    .then(resp => {
                        const token = resp.data;
                        const username = credentials.username;

                        var base64Url = token.split('.')[1];
                        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                        }).join(''));
                        var role = JSON.parse(jsonPayload).role;

                        localStorage.setItem('token', token);
                        localStorage.setItem('role', role);
                        axios.defaults.headers.common['Authorization'] = token;
                        commit('auth', {token, username, role});
                        resolve(resp)
                    })
                    .catch(err => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('role');
                        localStorage.removeItem('date');
                        reject(err)
                    })
            })
        },
        logout({commit}) {
            return new Promise(resolve => {
                commit('logout');
                localStorage.removeItem('token');
                localStorage.removeItem('role');
                delete axios.defaults.headers.common['Authorization'];
                resolve()
            })
        }
    },
    mutations: {
        auth(state, payload) {
            state.token = payload.token;
            state.username = payload.username
            state.role = payload.role
        },
        loading(state, payload) {
            state.loading = payload
        },
        logout(state) {
            state.token = '';
            state.user = '';
            state.role = '';
        },
    },
    getters: {
        isLoggedIn: state => !!state.token,
        isLoading: state => state.loading,
        username: state => state.username,
        role: state => state.role,
        token: state => state.token
    }
};
export default auth