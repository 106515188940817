<template>
  <v-main>
    <Navbar/>
    <main class="mx-4 mb-4">
      <slot/>
    </main>
  </v-main>
</template>

<script>
import Navbar from "../components/Navbar";

export default {
  components: {Navbar}
};
</script>