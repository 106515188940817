import axios from "../services/axios";

const documentTypes = {
    namespaced: true,
    strict: true,
    state: {
        list: []
    },
    actions: {

        fetch({commit}) {
            axios.send({
                url: "/document/types",
                method: "GET"
            }).then(resp => {
                commit('fetch', resp.data);
            });
        },

        remove({commit}, item) {
            axios.send({
                url: "/document/types",
                method: "DELETE",
                data: item
            }).then(() => {
                commit('remove', item);
            });
        },

        save({commit}, item) {
            axios.send({
                url: "/document/types",
                method: "POST",
                data: item
            }).then(resp => {
                commit('set', resp.data);
            });
        }
    },
    mutations: {
        fetch(state, payload) {
            state.list = payload;
        },
        set(state, payload) {
            let item = state.list.find(item => item.id === payload.id);
            if (item) {
                item.name = payload.name;
                item.fields = payload.fields;
            } else {
                state.list.push(payload);
            }
        },
        remove(state, payload) {
            state.list = state.list.filter(function (i) {
                return i.id !== payload.id;
            });
        },
    },
    getters: {
        list: state => state.list,
        headers: state => state.headers,
        item: (state, getters) => id => {
            if (state.list) {
                return getters.list.find(item => item.id === id);
            } else {
                return null
            }
        }
    }
};
export default documentTypes