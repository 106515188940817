import axios from "../services/axios";

const clients = {
    namespaced: true,
    strict: true,
    state: {
        headers: [
            {
                text: "",
                value: "image",
                class: "primary--text"
            },
            {
                text: "Телефон",
                value: "phone",
                class: "primary--text"
            },
            {
                text: "Имя",
                value: "name",
                class: "primary--text"
            },
            {
                text: "Фамилия",
                value: "surname",
                class: "primary--text"
            },
            {
                text: "Почта",
                value: "email",
                class: "primary--text"
            },
            {
                text: "ДР",
                value: "birthday",
                class: "primary--text"
            },
            {
                text: "Дата регистрации",
                value: "created_at",
                class: "primary--text"
            },
            {
                text: "Дата активности",
                value: "updated_at",
                class: "primary--text"
            },
            {
                text: "Пол",
                value: "gender",
                class: "primary--text"
            }
        ],
        list: [],
        count: 0,
    },
    actions: {
        fetch({commit}, payload) {
            axios.request({
                url: "/clients?" + payload,
                method: "GET",
            }).then(resp => {
                commit('set', resp.data);
            });
        },
        save({commit}, client) {
            return new Promise(((resolve, reject) => {
                axios.send({
                    url: "/clients",
                    method: "POST",
                    data: client
                }).then(resp => {
                    commit('save', resp.data);
                    resolve();
                }).catch(err => {
                    reject(err);
                });
            }))
        },
        add({commit}, id) {
            return new Promise((resolve) => {
                axios.send({
                    url: "/clients/" + id,
                    method: "PUT",
                }).then(resp => {
                    commit('add', resp.data);
                    resolve();
                });
            })
        },
        remove({commit}, id) {
            return new Promise((resolve) => {
                axios.send({
                    url: "/clients/" + id,
                    method: "DELETE",
                }).then(resp => {
                    commit('remove', resp.data);
                    resolve();
                });
            })
        },
    },
    mutations: {
        save(state, payload) {
            if (state.list) {
                let item = state.list.find(item => item.id === payload.id);
                if (item) {
                    item.description = payload.description;
                } else {
                    state.list.push(payload);
                }
            }
        },
        set(state, payload) {
            state.list = payload.clients;
            state.count = payload.count;
        },
        add(state, payload) {
            if (state.list) {
                let item = state.list.find(item => item.id === payload.id);
                item.blocked = payload.blocked;
            } else {
                return [];
            }
        },
        remove(state, payload) {
            if (state.list) {
                let item = state.list.find(item => item.id === payload.id);
                item.blocked = payload.blocked;
            } else {
                return [];
            }
        },
    },
    getters: {
        list: state => {
            if (state.list) {
                return state.list;
            } else {
                return [];
            }
        },
        headers: state => state.headers,
        count: state => state.count
    }
};
export default clients