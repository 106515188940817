<template>
  <nav>
    <v-app-bar :clipped-left="$vuetify.breakpoint.mdAndUp" dark color="primary" app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"/>
      <v-btn icon large>
        <v-avatar size="32" class="grey lighten-2">
          <v-img src="/logo.png" @click="home"/>
        </v-avatar>
      </v-btn>
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down font-weight-bold">Kenguroo App</span>
      </v-toolbar-title>
      <v-spacer/>
      <v-btn @click="logout" text>
        <span class="font-weight-bold">Выход</span>
        <v-icon right>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
        v-model="drawer"
        :clipped="$vuetify.breakpoint.mdAndUp"
        app
    >
      <v-list dense>
        <div v-for="item in items" :key="item.route">
          <div v-if="hasAccess(item)">
            <v-list-group v-if="item.children"
                          :key="item.route"
                          v-model="item.model"
                          hoverable>

              <template v-slot:activator>
                <v-list-item class="pl-0">
                  <v-list-item-action>
                    <v-icon class="primary--text">
                      {{ item.icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <div v-for="(child, i) in item.children" :key="i">
                <div v-if="hasAccess(child)">
                  <v-list-item link :to="child.route">
                    <v-list-item-action v-if="child.icon">
                      <v-icon class="primary--text ml-6">
                        {{ child.icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title class="caption">
                        {{ child.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </div>
            </v-list-group>
            <v-list-item v-else :key="item.text" link :to="item.route">
              <v-list-item-action>
                <v-icon class="primary--text">
                  {{ item.icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </div>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
export default {
  data: () => ({
    drawer: null,
    items: [
      {
        icon: "mdi-cog",
        text: "Настройки",
        route: "/settings",
        access: ["ADMIN"],
      },
      {
        icon: "mdi-account",
        text: "Клиенты",
        route: "/users",
        access: ["ADMIN"],
      },
      {
        icon: "mdi-moped",
        icon_alt: "mdi-map-marker",
        text: "Курьеры",
        access: ["ADMIN", "MONITOR"],
        children: [
          {
            icon: "mdi-format-list-bulleted",
            text: "Список",
            route: "/drivers",
            access: ["ADMIN", "MONITOR"],
          },
          {
            icon: "mdi-list-status",
            text: "Статус",
            route: "/status/drivers",
            access: ["ADMIN", "MONITOR"],
          },
          {
            icon: "mdi-arrow-top-right",
            text: "Статистика",
            route: "/stat/drivers",
            access: ["ADMIN", "MONITOR"]
          },
          {
            icon: "mdi-car",
            text: "Виды транспорта",
            route: "/vehicle-type",
            access: ["ADMIN", "MONITOR"],
          },
          {
            icon: "mdi-file-document",
            text: "Виды документов",
            route: "/document-type",
            access: ["ADMIN", "MONITOR"],
          },
        ]
      },
      {
        icon: "mdi-map-marker",
        icon_alt: "mdi-map-marker",
        text: "Карты",
        access: ["ADMIN", "MONITOR"],
        children: [
          {
            icon: "mdi-cash",
            text: "Карта",
            route: "/monitor",
            access: ["ADMIN", "MONITOR"],
          },
          {
            icon: "mdi-map",
            text: "Полигон",
            route: "/polygon",
            access: ["ADMIN"]
          },
          {
            icon: "mdi-map-marker-radius",
            text: "Тепловая карта",
            route: "/heatmap",
            access: ["ADMIN"]
          },
        ]
      },
      {
        icon: "mdi-silverware-variant",
        icon_alt: "mdi-map-marker",
        text: "Заведения",
        access: ["ADMIN", "CONTENT"],
        children: [
          {
            icon: "mdi-format-list-bulleted",
            text: "Список",
            route: "/stores",
            access: ["ADMIN", "CONTENT"],
          },
          {
            icon: "mdi-list-status",
            text: "Статус",
            route: "/status/stores",
            access: ["ADMIN", "CONTENT"],
          },
          {
            icon: "mdi-format-align-justify",
            text: "Категории",
            route: "/tags",
            access: ["ADMIN", "CONTENT"],
          },
          {
            icon: "mdi-sale",
            text: "Акции и скидки",
            route: "/discounts",
            access: ["ADMIN", "CONTENT"],
          },
        ]
      },
      {
        icon: "mdi-cart",
        text: "Заказы",
        route: "/orders",
        access: ["ADMIN", "MONITOR"],
      },
      {
        icon: "mdi-car-info",
        text: "Условия доставки",
        route: "/rules",
        access: ["ADMIN"],
      },
      {
        icon: "mdi-newspaper-variant-outline",
        icon_alt: "mdi-map-marker",
        text: "Рек. и новости",
        access: ["ADMIN"],
        children: [
          {
            icon: "mdi-folder-text",
            text: "Новости",
            route: "/news",
            access: ["ADMIN"],
          },
          {
            icon: "mdi-offer",
            text: "Рекомендации",
            route: "/recommendations",
            access: ["ADMIN"],
          },
        ]
      },
      {
        icon: "mdi-format-list-bulleted-type",
        text: "Методы оплаты",
        route: "/payment",
        access: ["ADMIN"],
      },
      {
        icon: "mdi-account-cash",
        icon_alt: "mdi-map-marker",
        text: "Расчет курьера",
        access: ["ADMIN", "MONITOR"],
        children: [
          {
            icon: "mdi-car-info",
            text: "Правила",
            route: "/calculation/rules",
            access: ["ADMIN"],
          },
          {
            icon: "mdi-calculator-variant",
            text: "Калькуляция",
            route: "/calculation/shiftClose/index",
            access: ["ADMIN", "MONITOR"]
          },
          {
            icon: "mdi-account-clock",
            text: "Расчет",
            route: "/calculation/payments/index",
            access: ["ADMIN", "MONITOR"]
          },
        ]
      },
      {
        icon: "mdi-chart-bar",
        text: "Отчеты по заказам",
        route: "/reports/reports",
        access: ["ADMIN", "VIEWER"],
      },
      {
        icon: "mdi-tooltip-text",
        text: "Версия",
        route: "/version",
        access: ["ADMIN"],
      },
    ],
  }),
  computed: {
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
    role() {
      return this.$store.getters["auth/role"];
    },
    token() {
      return this.$store.getters["auth/token"];
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push("/login");
      });
    },
    home() {
      this.$router.push({name: "orders"});
    },
    hasAccess(item) {
      return item.access.includes(this.role);
    },
  },
};
</script>
