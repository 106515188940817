import axios from "../services/axios";

const drivers = {
    namespaced: true,
    strict: true,
    state: {
        headers: [
            {
                text: "",
                value: "photo",
                class: "primary--text"
            },
            {
                text: "Телефон",
                value: "phone",
                class: "primary--text"
            },
            {
                text: "Имя",
                value: "name",
                class: "primary--text"
            },
            {
                text: "Почта",
                value: "email",
                class: "primary--text"
            },
            {
                text: "Активный",
                value: "active",
                class: "primary--text"
            },
            {
                text: "Удален",
                value: "deleted_at",
                class: "primary--text"
            },
            {
                text: "Actions",
                value: "action",
                sortable: false,
                class: "primary--text"
            }
        ],
        list: [],
        count: 0,
        vehicles: [],
        documents: [],
    },
    actions: {
        upload({commit}, payload) {
            let formData = new FormData();
            formData.append("image", payload.file);

            axios.send({
                url: "/drivers/" + payload.id,
                method: "POST",
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(resp => {
                commit('upload', {id: payload.id, data: resp.data});
            });
        },

        fetch({commit}, payload) {
            axios.request({
                url: "/drivers?"+payload,
                method: "GET"
            })
                .then(resp => {
                    commit('fetch', resp.data);
                });
        },

        fetchVehicle({commit}, driverID) {
            axios.request({
                url: "/drivers/vehicles/" + driverID,
                method: "GET"
            })
                .then(resp => {
                    commit('fetchVehicle', resp.data);
                });
        },

        removeVehicle({commit}, vehicleID) {
            axios.request({
                url: "/drivers/vehicles/" + vehicleID,
                method: "DELETE"
            })
                .then(() => {
                    commit('removeVehicle', vehicleID);
                });
        },

        fetchDocument({commit}, driverID) {
            axios.request({
                url: "/drivers/documents/" + driverID,
                method: "GET"
            }).then(resp => {
                commit('fetchDocument', resp.data);
            });
        },

        uploadDocument({commit}, payload) {
            let formData = new FormData();
            formData.append("image", payload.file);

            return new Promise((resolve, reject) => {
                axios.send({
                    url: "/drivers/documents/upload/" + payload.driver_id,
                    method: "POST",
                    data: formData,
                    headers: {"Content-Type": "multipart/form-data"}
                }).then(resp => {
                    payload.data = resp.data;
                    commit('uploadDocument', payload);
                    resolve(resp.data);
                });

                reject();
            });
        },

        saveDocument({commit}, payload) {
            axios.request({
                url: "/drivers/documents/save",
                method: "POST",
                data: payload
            })
                .then(() => {
                    commit('fetchDocument', payload.driver_id);
                });
        },

        remove({commit}, item) {
            axios.send({
                url: "/drivers",
                method: "DELETE",
                data: item
            }).then(() => {
                commit('remove', item);
            });
        },

        save({commit}, item) {
            axios.send({
                url: "/drivers",
                method: "PUT",
                data: item
            }).then(resp => {
                commit('set', resp.data);
            });
        },

        refill({commit}, item) {
            axios.send({
                url: "/drivers/refill",
                method: "POST",
                data: item
            }).then(resp => {
                commit('set', resp.data);
            });
        },

        create({commit}, item) {
            axios.send({
                url: "/drivers",
                method: "POST",
                data: item
            }).then(resp => {
                commit('set', resp.data);
            });
        }
    },
    mutations: {
        fetch(state, payload) {
            state.list = payload.list;
            state.count = payload.count;
        },
        fetchVehicle(state, payload) {
            state.vehicles = payload;
        },
        fetchDocument(state, payload) {
            state.documents = payload;
        },
        set(state, payload) {
            let item = state.list.find(item => item.id === payload.id);
            if (item) {
                item.name = payload.name;
                item.phone = payload.phone;
                item.email = payload.email;
                item.photo = payload.photo;
                item.active = payload.active;
            } else {
                state.list.push(payload);
            }
        },
        remove(state, payload) {
            state.list = state.list.filter(function (i) {
                return i.id !== payload.id;
            });
        },
        removeVehicle(state, payload) {
            state.vehicles = state.vehicles.filter(function (i) {
                return i.id !== payload;
            });
        },
        upload(state, payload) {
            let item = state.list.find(item => item.id === payload.id);
            item.photo = payload.data;
        },
        uploadDocument(state, payload) {
            let item = state.documents.find(item => item.id === payload.id);
            if (item) {
                item.fields.forEach(f => {
                    if (f.key === payload.key) {
                        f.value = payload.data;
                    }
                })
            }
        }
    },
    getters: {
        list: state => state.list,
        count: state => state.count,
        headers: state => state.headers,
        item: (state, getters) => id => getters.list.find(item => item.id === id),
        vehicles: state => {
            if (state.vehicles) {
                return state.vehicles
            } else {
                return []
            }
        },
        documents: state => state.documents,
        document: (state, getters) => id => getters.documents.find(item => item.id === id),
    }
};
export default drivers