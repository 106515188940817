import axios from "@/services/axios";

export default {
    namespaced: true,
    strict: true,
    state: {
        list: [],
        item: null,
        storeList: []
    },
    actions: {
        fetchList({commit}) {
            axios.send({
                url: "/recommendations",
                method: "GET"
            }).then(resp => {
                commit("fetchList", resp.data);
            });
        },
        fetch({commit}, id) {
            return new Promise((resolve, reject) => {
                axios.send({
                    url: `/recommendations/${id}`,
                    method: "GET"
                }).then(resp => {
                    commit("fetch", resp.data);
                    resolve();
                }).catch((err) => {
                    reject(err);
                });
            })
        },
        enable({commit}, id) {
            return new Promise((resolve, reject) => {
                axios.send({
                    url: `/recommendations/active/${id}`,
                    method: "POST"
                }).then(resp => {
                    commit("fetch", resp.data);
                    resolve();
                }).catch((err) => {
                    reject(err);
                });
            })
        },
        disable({commit}, id) {
            return new Promise((resolve, reject) => {
                axios.send({
                    url: `/recommendations/active/${id}`,
                    method: "DELETE"
                }).then(resp => {
                    commit("fetch", resp.data);
                    resolve();
                }).catch((err) => {
                    reject(err);
                });
            })
        },
        create({commit, dispatch}, data) {
            data.priority = parseInt(data.priority);
            axios
                .send({
                    url: "/recommendations",
                    method: "POST",
                    data: data,
                })
                .then((resp) => {
                    commit("create", resp.data);
                    dispatch("fetchList");
                });
        },
        update({commit}, data) {
            data.priority = parseInt(data.priority);
            axios
                .send({
                    url: "/recommendations",
                    method: "PUT",
                    data: data,
                })
                .then((resp) => {
                    commit("update", resp.data);
                });
        },
        delete({dispatch}, id) {
            axios
                .send({
                    url: `/recommendations/${id}`,
                    method: "DELETE",
                })
                .then(() => {
                    dispatch("fetchList")
                });
        },
        upload({dispatch}, payload) {
            let formData = new FormData();
            formData.append("image", payload.file);

            axios.send({
                url: "/recommendations/upload/" + payload.id,
                method: "POST",
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(() => {
                dispatch("fetchList")
            });
        },
        createStore({commit}, data) {
            return new Promise((resolve) => {
                data.priority = parseInt(data.priority);
                axios
                    .send({
                        url: "/recommendations/store",
                        method: "POST",
                        data: data,
                    })
                    .then((resp) => {
                        commit("createStore", resp.data);
                        resolve();
                    });
            })
        },
        updateStore({commit}, data) {
            return new Promise((resolve) => {
                data.priority = parseInt(data.priority);
                axios
                    .send({
                        url: "/recommendations/store",
                        method: "PUT",
                        data: data,
                    })
                    .then((resp) => {
                        commit("updateStore", resp.data);
                        resolve();
                    });
            })
        },
        deleteStore({commit}, store) {
            return new Promise((resolve) => {
                axios
                    .send({
                        url: `/recommendations/store/${store.id}`,
                        method: "DELETE",
                    })
                    .then(() => {
                        commit("deleteStore", store);
                        resolve();
                    });
            })
        },

    },
    mutations: {
        fetchList(state, payload) {
            state.list = payload;
        },
        fetch(state, payload) {
            state.item = payload;
        },
        create(state, payload) {
            state.item = payload;
        },
        update(state, payload) {
            state.item = payload;
        },
        deleteStore(state, payload) {
            state.item.stores = state.item.stores.filter(el => el.id !== payload.id)
        },
        updateStore(state, payload) {
            for (let i = 0; i < state.item.stores.length; i++) {
                if (state.item.stores[i].id === payload.id) {
                    state.item.stores[i] = payload;
                }
            }
        },
        createStore(state, payload) {
            if (!state.item.stores) {
                state.item.stores = []
            }
            state.item.stores.push(payload);
        },
    },
    getters: {
        list: (state) => state.list,
        item: (state) => state.item,
        storeList: (state) => state.storeList
    }
}