import axios from "../services/axios";

export default {
    namespaced: true,
    strict: true,
    state: {
        list: [],
        count: 0,
        item: null
    },
    actions: {
        fetch({commit}, data) {
            axios
                .get(`/payments?offset=${data.offset}&limit=${data.limit}`)
                .then((resp) => {
                    commit("fetch", resp.data);
                });
        },
        get({commit}, id) {
            axios
                .get(`/payments/${id}`)
                .then((resp) => {
                    commit('get', resp.data)
                });
        },
        update({commit}, data) {
            axios
                .put('/payments', data)
                .then((resp) => {
                    commit('update', resp.data)
                });
        },
        enabledUpload({commit}, payload) {
            let formData = new FormData();
            formData.append("image", payload.file);

            axios.send({
                url: "/payments/enabled/" + payload.id,
                method: "POST",
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then((resp) => {
                commit('update', resp.data)
            });
        },
        disabledUpload({commit}, payload) {
            let formData = new FormData();
            formData.append("image", payload.file);

            axios.send({
                url: "/payments/disabled/" + payload.id,
                method: "POST",
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then((resp) => {
                commit('update', resp.data)
            });
        },
        selectedUpload({commit}, payload) {
            let formData = new FormData();
            formData.append("image", payload.file);

            axios.send({
                url: "/payments/selected/" + payload.id,
                method: "POST",
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then((resp) => {
                commit('update', resp.data)
            });
        },
        create({commit}, body) {
            axios
                .post("/payments", body)
                .then(resp => {
                    commit('create', resp.data)
                });
        },
        delete({commit}, id) {
            axios({
                url: `/payments/${id}`,
                method: "DELETE",
            }).then(() => {
                commit('delete', id);
            });
        }
    },
    mutations: {
        fetch(state, payload) {
            state.list = payload.list;
            state.count = payload.count;
        },
        get(state, payload) {
            state.item = payload
        },
        create(state, payload) {
            if (!state.list) {
                state.list = []
            }
            state.list.push(payload);
        },
        update(state, payload) {
            let index = state.list.findIndex(item => item.id === payload.id);
            state.list[index].title = payload.title
            state.list[index].subtitle = payload.subtitle
            state.list[index].priority = payload.priority
            state.list[index].active = payload.active
            state.list[index].enabled_icon = payload.enabled_icon
            state.list[index].disabled_icon = payload.disabled_icon
            state.list[index].selected_icon = payload.selected_icon
        },
        delete(state, id) {
            state.list = state.list.filter(item => item.id !== id);
        }
    },
    getters: {
        list: (state) => state.list,
        count: (state) => state.count,
        item: (state) => state.item,
    },
};