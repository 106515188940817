import axios from "../services/axios";

const tags = {
    namespaced: true,
    strict: true,
    state: {
        headers: [
            {
                text: "Наименование",
                value: "name",
                class: "primary--text"
            },
            {
                text: "Ключ",
                value: "key",
                class: "primary--text"
            },
            {
                text: "Очередность",
                value: "priority",
                class: "primary--text"
            },
            {
                text: "Actions",
                value: "action",
                sortable: false,
                class: "primary--text"
            }
        ],
        list: []
    },
    actions: {
        upload({commit}, payload) {
            let formData = new FormData();
            formData.append("image", payload.file);

            axios.send({
                url: "/tags/" + payload.id,
                method: "POST",
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(resp => {
                commit('upload', {id: payload.id, data: resp.data});
            });
        },
        fetch({commit}) {
            axios.send({
                url: "/tags",
                method: "GET"
            }).then(resp => {
                commit('fetch', resp.data);
            })
        },

        remove({commit}, item) {
            axios.send({
                url: "/tags",
                method: "DELETE",
                data: item
            }).then(() => {
                commit('remove', item);
            });
        },

        save({commit}, item) {
            axios.send({
                url: "/tags",
                method: "POST",
                data: item
            }).then(resp => {
                commit('set', resp.data);
            });
        }
    },
    mutations: {
        fetch(state, payload) {
            state.list = payload;
        },
        set(state, payload) {
            let item = state.list.find(item => item.id === payload.id);
            if (item) {
                item.name = payload.name;
                item.key = payload.key;
                item.priority = payload.priority;
            } else {
                state.list.push(payload);
            }
        },
        remove(state, payload) {
            state.list = state.list.filter(function (i) {
                return i.id !== payload.id;
            });
        },
        upload(state, payload) {
            let item = state.list.find(item => item.id === payload.id);
            item.image = payload.data;
        }
    },
    getters: {
        list: state => state.list,
        headers: state => state.headers,
        item: (state, getters) => id => {
            return getters.list.find(item => item.id === id);
        }
    }
};
export default tags