import axios from "@/services/axios";

export default {
    namespaced: true,
    strict: true,
    state: {
        list: [],
        partner: [],
        active: []
    },
    actions: {
        fetch({commit}) {
            axios.send({
                url: "/store?offset=0&limit=1000",
                method: "GET"
            }).then(resp => {
                commit("fetch", resp.data.list);
            });
        },
        fetchActive({commit}) {
            axios.send({
                url: "/store/active",
                method: "GET"
            }).then(resp => {
                commit("fetchActive", resp.data);
            });
        },
        fetchPartner({commit}) {
            axios.send({
                url: "/store/partner",
                method: "GET"
            }).then(resp => {
                commit("fetchPartner", resp.data);
            });
        },
    },
    mutations: {
        fetch(state, payload) {
            state.list = payload;
        },
        fetchPartner(state, payload) {
            state.partner = payload;
        },
        fetchActive(state, payload) {
            state.active = payload;
        },
    },
    getters: {
        list: (state) => state.list,
        partner: (state) => state.partner,
        active: (state) => state.active
    }
}
