import axios from "@/services/axios";

export default {
    namespaced: true,
    strict: true,
    state: {
        active: [],
        finished: [],
        canceled: []
    },
    actions: {
        searchActive({commit}, payload) {
            axios.send({
                url: "/orders/active?date=" + payload,
                method: "GET",
            }).then((resp) => {
                commit("searchActive", resp.data);
            });
        },
        searchFinished({commit}, payload) {
            axios.send({
                url: "/orders/finished?date=" + payload,
                method: "GET",
            }).then((resp) => {
                commit("searchFinished", resp.data);
            });
        },
        searchCanceled({commit}, payload) {
            axios.send({
                url: "/orders/canceled?date=" + payload,
                method: "GET",
            }).then((resp) => {
                commit("searchCanceled", resp.data);
            });
        }
    },
    mutations: {
        searchActive(state, payload) {
            state.active = payload;
        },
        searchFinished(state, payload) {
            state.finished = payload;
        },
        searchCanceled(state, payload) {
            state.canceled = payload;
        }
    },
    getters: {
        active: state => {
            if (state.active) {
                return state.active
            } else {
                return [];
            }
        },
        finished: state => {
            if (state.finished) {
                return state.finished
            } else {
                return [];
            }
        },
        canceled: state => {
            if (state.canceled) {
                return state.canceled
            } else {
                return [];
            }
        }
    }
}
