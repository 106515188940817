import axios from "@/services/axios";

export default {
    namespaced: true,
    strict: true,
    state: {
        list: [],
        closedList: [],
        vehicleTypes: [],
        rules: []
    },
    actions: {
        fetch({commit}, payload) {
            return new Promise((resolve) => {
                axios.send({
                    url: "/stat/drivers?date=" + payload,
                    method: "GET",
                }).then((resp) => {
                    commit("fetch", resp.data);
                    resolve();
                })
            })
        },
        fetchClosed({commit}, payload) {
            return new Promise((resolve) => {
                axios.send({
                    url: "/drivers/payments/date/" + payload,
                    method: "GET",
                }).then((resp) => {
                    commit("fetchClosed", resp.data);
                    resolve();
                })
            })
        },
        fetchClosedRange({commit}, payload) {
            return new Promise((resolve) => {
                axios.send({
                    url: "/drivers/payments/range?" + payload,
                    method: "GET",
                }).then((resp) => {
                    commit("fetchClosed", resp.data);
                    resolve();
                })
            })
        },
        close({commit}, payload) {
            axios.send({
                url: "/drivers/payments/close",
                method: "POST",
                data: payload
            }).then((resp) => {
                commit("close", resp.data)
            })
        },
        fetchVehicle({commit}) {
            axios.send({
                url: "/vehicle/types",
                method: "GET"
            }).then(resp => {
                commit("fetchVehicle", resp.data);
            });
        },
        fetchRules({commit}) {
            axios.send({
                url: "/drivers/rules",
                method: "GET"
            }).then(resp => {
                commit("fetchRules", resp.data)
            })
        },
        cash({commit}, payload) {
            axios.send({
                url: "/drivers/payments/cash/" + payload,
                method: "POST",
            }).then((resp) => {
                commit("set", resp.data)
            })
        },
        cashless({commit}, payload) {
            axios.send({
                url: "/drivers/payments/cashless/" + payload,
                method: "POST",
            }).then((resp) => {
                commit("set", resp.data)
            })
        },
        remove({commit}, payload) {
                axios.send({
                    url: "/drivers/payments/" + payload,
                    method: "DELETE",
                }).then(() => {
                    commit("remove", payload);
                })
        },
    },
    mutations: {
        fetch(state, payload) {
            state.list = payload;
        },
        fetchClosed(state, payload) {
            state.closedList = payload;
        },
        close(state, payload) {
            state.closedList.push(payload)
        },
        fetchVehicle(state, payload) {
            state.vehicleTypes = payload
        },
        fetchRules(state, payload) {
            state.rules = payload
        },
        set(state, payload) {
            let item = state.closedList.find(item => item.id === payload.id)
                if (item) {
                    item.payed_at = payload.payed_at;
                    item.cashless_payed_at = payload.cashless_payed_at;
                }
        },
        remove(state, payload) {
            state.closedList = state.closedList.filter(item => item.id !== payload);
        }

    },
    getters: {
        list: state => {
            if (state.list) {
                return state.list
            } else {
                return [];
            }
        },
        closedList: state => {
            if (state.closedList) {
                return state.closedList
            } else {
                return [];
            }
        },
        vehicleTypes: state => {
            if (state.vehicleTypes) {
                return state.vehicleTypes
            } else {
                return [];
            }
        },
        rules: state => {
            if (state.rules) {
                return state.rules
            } else {
                return []
            }
        }
    }
}
