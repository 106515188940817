import axios from "../services/axios";

export default {
    namespaced: true,
    strict: true,
    state: {
        list: [],
        count: 0,
        error: null,
        item: null
    },
    actions: {
        fetch({commit}) {
            axios.send({
                url: "/news",
                method: "GET"
            }).then(resp => {
                commit("fetch", resp.data);
            }).catch((error) => {
                commit("error", error);
            })
        },
        getOne({commit}, id) {
            axios.send({
                url: `/news/${id}`,
                method: "GET"
            }).then((resp) => {
                commit('getOne', resp.data)
            }).catch((error) => {
                commit("error", error)
            })
        },
        updateOne({commit}, body) {
            axios.send({
                url: '/news',
                method: "PUT",
                data: body
            }).then((resp) => {
                commit("updateOne", resp.data);
            }).catch((error) => {
                commit('error', error)
            })
        },
        uploadImage({dispatch}, payload) {
           let formData = new FormData();
           formData.append("image", payload.file)
            axios.send({
                url: `/news/upload/${payload.id}/`,
                method: "POST",
                data: formData,
                headers: {"Content-Type": "multipart/form-data",},
            }).then(() => {
                dispatch("fetch")
            })
        },
        create({commit, dispatch}, body) {
            axios.send({
                url: "/news",
                method: "POST",
                data: body
            }).then(resp => {
                commit("created", resp.data);
                dispatch("fetch");
            })
        },
        deleteOne({commit, dispatch}, id) {
            axios({
                url: "/news",
                method: "DELETE",
                data: {id}
            }).then(() => {
                dispatch("fetch");
            }).catch((error) => {
                commit('error', error)
            })
        }
    },
    mutations: {
        fetch(state, payload) {
            state.list = payload;
            state.count = payload.length
        },
        error(state, payload) {
            state.error = payload.error;
        },
        created(state, payload) {
            state.newNews = payload;
        },
        getOne(state, payload) {
            state.item = payload;
        },
        updateOne(state, payload) {
        state.item = payload
        }
    },
    getters: {
        news: (state) => state.list,
        count: (state) => state.count,
        item: (state) => state.item,
        error: (state) => state.error,
    }
}
