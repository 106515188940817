import axios from "../services/axios";

export default {
    namespaced: true,
    strict: true,
    state: {
        ordersList: [],
        ordersCount: 0,
        storesList: [],
        storesCount: 0,
        datesList: [],
        datesCount: 0,
        storesDatesList: [],
        storesDatesCount: 0,
        missed: [],
        missedCount: 0,
        driversList: [],
        driversCount: 0,
        transactionsList: []
    },
    actions: {
        fetchOrders({commit}, payload) {
            axios({
                url: "/reports/orders?" + payload,
                method: 'GET',
            }).then((resp) => {
                commit('fetchOrders', resp.data)
            });
        },
        fetchStores({commit}, payload) {
            axios({
                url: "/reports/stores?" + payload,
                method: 'GET',
            }).then((resp) => {
                commit('fetchStores', resp.data)
            });
        },
        fetchDates({commit}, payload) {
            axios({
                url: "/reports/dates?" + payload,
                method: 'GET',
            }).then((resp) => {
                commit('fetchDates', resp.data)
            });
        },
        fetchStoresDates({commit}, payload) {
            axios({
                url: "/reports/stores_dates?" + payload,
                method: 'GET',
            }).then((resp) => {
                commit('fetchStoresDates', resp.data)
            });
        },
        fetchMissed({commit}, payload) {
            axios({
                url: "/reports/missed?" + payload,
                method: 'GET',
            }).then((resp) => {
                commit('fetchMissed', resp.data)
            });
        },
        fetchDrivers({commit}, payload) {
            axios({
                url: "/reports/drivers?" + payload,
                method: 'GET',
            }).then((resp) => {
                commit('fetchDrivers', resp.data)
            });
        },
        fetchTransactions({commit}, payload) {
            axios({
                url: "/reports/transactions?" + payload,
                method: 'GET',
            }).then((resp) => {
                commit('fetchTransactions', resp.data)
            });
        },
    },
    mutations: {
        fetchOrders(state, payload) {
            state.ordersList = payload.list;
            state.ordersCount = payload.count;

        },
        fetchStores(state, payload) {
            state.storesList = payload.list;
            state.storesCount = payload.count;
        },
        fetchDates(state, payload) {
            state.datesList = payload.list;
            state.datesCount = payload.count;
        },
        fetchStoresDates(state, payload) {
            state.storesDatesList = payload.list;
            state.storesDatesCount = payload.count;
        },
        fetchMissed(state, payload) {
            state.missed = payload;
            state.missedCount = payload.length;
        },
        fetchDrivers(state, payload) {
            state.driversList = payload.list;
            state.driversCount = payload.count;
        },
        fetchTransactions(state, payload) {
            state.transactionsList = payload;
        },
    },
    getters: {
        ordersList: state => {
            if (state.ordersList) {
                return state.ordersList;
            } else {
                return [];
            }
        },
        ordersCount: state => state.ordersCount,

        storesList: state => {
            if (state.storesList) {
                return state.storesList;
            } else {
                return [];
            }
        },
        storesCount: state => state.storesCount,

        datesList: state => {
            if (state.datesList) {
                return state.datesList;
            } else {
                return [];
            }
        },
        datesCount: state => state.datesCount,

        storesDatesList: state => {
            if (state.storesDatesList) {
                return state.storesDatesList;
            } else {
                return [];
            }
        },
        storesDatesCount: state => state.storesDatesCount,

        missed: state => {
            if (state.missed) {
                return state.missed;
            } else {
                return [];
            }
        },
        missedCount: state => state.missedCount,
        driversList: state => {
            if (state.driversList) {
                return state.driversList;
            } else {
                return [];
            }
        },
        driversCount: state => state.driversCount,
        transactionsList: state => state.transactionsList
    }
}
