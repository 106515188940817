import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import clients from './clients'
import tags from './tags'
import categories from './categories'
import items from './items'
import documentTypes from './documentTypes'
import drivers from './drivers'
import news from './news'
import recommendations from "./recommendations";
import stores from "./stores";
import payments from "./payments";
import reports from "./reports";
import calculation from "./calculation";
import orders from "./orders";
import order from "./order";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        clients,
        tags,
        categories,
        items,
        documentTypes,
        drivers,
        news,
        recommendations,
        stores,
        payments,
        reports,
        calculation,
        orders,
        order
    }
})
