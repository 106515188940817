import axios from "../services/axios";

const stores = {
    namespaced: true,
    strict: true,
    state: {
        headers: [
            {
                text: "Изображение",
                value: "image",
                class: "primary--text"
            },
            {
                text: "Наименование",
                value: "name",
                class: "primary--text"
            },
            {
                text: "Детали",
                value: "description",
                class: "primary--text"
            },
            {
                text: "Уведомление",
                value: "notification",
                class: "primary--text"
            },
            {
                text: "Масса",
                value: "mass",
                class: "primary--text"
            },
            {
                text: "Цена",
                value: "price",
                class: "primary--text"
            },
            {
                text: "Скрыто",
                value: "hidden",
                class: "primary--text"
            },
            {
                text: "Время",
                value: "cook_time",
                class: "primary--text"
            },
            {
                text: "Начало",
                value: "start_at",
                class: "primary--text"
            },
            {
                text: "Завершение",
                value: "finish_at",
                class: "primary--text"
            },
            {
                text: "Actions",
                value: "action",
                sortable: false,
                class: "primary--text"
            },
            {
                text: "",
                value: "data-table-expand"
            }
        ],
        list: [],
        item: {}
    },
    actions: {
        uploadImage({commit}, payload) {
            let formData = new FormData();
            formData.append("image", payload.file);

            axios.send({
                url: "/items/" + payload.id,
                method: "POST",
                data: formData,
                headers: {"Content-Type": "multipart/form-data"}
            }).then(resp => {
                commit('uploadImage', {id: payload.id, data: resp.data});
            });
        },

        fetch({commit}, store_id) {
            return new Promise(((resolve) => {
                axios({
                    url: "/items/" + store_id,
                    method: "GET"
                }).then(resp => {
                    commit('set', resp.data);
                    resolve();
                });
            }))
        },

        fetchSingle({commit}, payload) {
            return new Promise(((resolve) => {
                axios({
                    url: "/items/" + payload.store_id + "/" + payload.item_id,
                    method: "GET"
                }).then(resp => {
                    commit('setSingle', resp.data);
                    resolve();
                });
            }))
        },

        remove({commit}, item) {
            axios({
                url: "/items",
                method: "DELETE",
                data: item
            }).then(() => {
                commit('remove', item);
            });
        },

        save({commit}, item) {
            item.cook_time = parseInt(item.cook_time);
            item.mass = parseInt(item.mass);
            item.price = parseFloat(item.price);
            commit('auth/loading', true, {root: true});

            return new Promise(((resolve, reject) => {
                axios({
                    url: "/items",
                    method: "POST",
                    data: item
                }).then(resp => {
                    commit('replace', resp.data);
                    commit('auth/loading', false, {root: true});
                    resolve();
                }).catch(err => {
                    commit('auth/loading', false, {root: true});
                    reject(err);
                });
            }))
        },

        addTag({commit}, payload) {
            commit('auth/loading', true, {root: true});

            axios({
                url: "/items/tags/" + payload.item.id + "/" + payload.tag.id,
                method: "PUT"
            }).then(() => {
                commit('auth/loading', false, {root: true});
                commit('addTag', payload);
            }).catch(() => {
                commit('auth/loading', false, {root: true});
            });
        },

        removeTag({commit}, payload) {
            commit('auth/loading', true, {root: true});

            axios({
                url: "/items/tags/" + payload.item.id + "/" + payload.tag.id,
                method: "DELETE"
            }).then(() => {
                commit('auth/loading', false, {root: true});
                commit('removeTag', payload);
            }).catch(() => {
                commit('auth/loading', false, {root: true});
            });
        }
    },
    mutations: {
        set(state, payload) {
            state.list = payload;
        },
        setSingle(state, payload) {
            state.item = payload;
        },
        replace(state, payload) {
            if (state.list) {
                state.list.forEach((item, index) => {
                    if (item.id === payload.id) {
                        state.list[index] = payload;
                    }
                })
            } else {
                state.list = [];
                state.list.push(payload);
            }
        },
        remove(state, payload) {
            state.list = state.list.filter(function (i) {
                return i.id !== payload.id;
            });
        },
        uploadImage(state, payload) {
            let item = state.list.find(item => item.id === payload.id);
            item.image = payload.data
        },
        addTag(state, payload) {
            let item = state.list.find(item => item.id === payload.item.id);
            item.categories.push(payload.tag);
        },
        removeTag(state, payload) {
            let item = state.list.find(item => item.id === payload.item.id);
            item.categories = item.categories.filter(function (value) {
                return value.id !== payload.tag.id;
            });
        },
    },
    getters: {
        list: state => {
            if (state.list) {
                return state.list;
            } else {
                return [];
            }
        },
        headers: state => state.headers,
        item: state => state.item
    }
};
export default stores
