import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
    {
        path: '/orders',
        name: 'orders',
        component: () => import('../views/orders/list.vue')
    },
    {
        path: '/order/canceled/:id',
        name: 'canceled_order',
        component: () => import('../views/order/index_canceled.vue')
    },
    {
        path: '/order/active/:id',
        name: 'active_order',
        component: () => import('../views/order/index_active.vue')
    },
    {
        path: '/order/finished/:id',
        name: 'finished_order',
        component: () => import('../views/order/index_finished.vue')
    },
    {
        path: "/login",
        name: "login",
        meta: {
            layout: "blank",
            public: true,
        },
        component: () =>
            import ("../views/login/index.vue"),
    },
    {
        path: "/users",
        name: "users",
        component: () =>
            import ("../views/clients/list.vue"),
    },
    {
        path: "/stores",
        name: "stores",
        component: () =>
            import ("../views/stores/index.vue"),
    },
    {
        path: "/stores/:id",
        name: "editStores",
        component: () =>
            import ("../views/stores/edit.vue"),
    },
    {
        path: "/items/:id",
        name: "items",
        component: () =>
            import ("../views/items/index.vue"),
    },
    {
        path: "/items/:store_id/:item_id",
        name: "editItems",
        component: () =>
            import ("../views/items/edit.vue"),
    },
    {
        path: "/tags",
        name: "tags",
        component: () =>
            import ("../views/tags/index.vue"),
    },
    {
        path: "/tags/:id",
        name: "editTags",
        component: () =>
            import ("../views/tags/edit.vue"),
    },
    {
        path: "/discounts",
        name: "discounts",
        component: () =>
            import ("../views/discounts/index.vue"),
    },
    {
        path: "/discounts/:id",
        name: "editDiscounts",
        component: () =>
            import ("../views/discounts/edit.vue"),
    },
    {
        path: "/vehicle-type",
        name: "vehicleType",
        component: () =>
            import ("../views/VehicleType.vue"),
    },
    {
        path: "/vehicle-type-edit/:id",
        name: "vehicleTypeEdit",
        component: () =>
            import ("../views/VehicleTypeEdit.vue"),
    },
    {
        path: "/document-type",
        name: "documentType",
        component: () =>
            import ("../views/DocumentType.vue"),
    },
    {
        path: "/document-type-edit/:id",
        name: "documentTypeEdit",
        component: () =>
            import ("../views/DocumentTypeEdit.vue"),
    },
    {
        path: "/drivers",
        name: "drivers",
        component: () =>
            import ("../views/drivers/index"),
    },
    {
        path: "/drivers/:id",
        name: "driversEdit",
        component: () =>
            import ("../views/drivers/edit.vue"),
    },
    {
        path: "/vehicles/:id",
        name: "driversVehicle",
        component: () =>
            import ("../views/drivers/vehicles/index"),
    },
    {
        path: "/documents/:id",
        name: "driversDocument",
        component: () =>
            import ("../views/DriversDocument.vue"),
    },
    {
        path: "/documents-edit/:driver_id/:id/:document_type_id",
        name: "driversDocumentEdit",
        component: () =>
            import ("../views/DriversDocumentEdit.vue"),
    },
    {
        path: "/rules",
        name: "rules",
        component: () =>
            import ("../views/rules/index"),
    },
    {
        path: "/monitor",
        name: "monitor",
        component: () =>
            import ("../views/maps/monitor.vue"),
    },
    {
        path: "/heatmap",
        name: "heatmap",
        component: () =>
            import ("../views/maps/heatmap.vue"),
    },
    {
        path: "/status/drivers",
        name: "statusDrivers",
        component: () =>
            import ("../views/status/driver/index.vue"),
    },
    {
        path: "/status/stores",
        name: "statusStores",
        component: () =>
            import ("../views/status/store/index.vue"),
    },
    {
        path: "/polygon",
        name: "polygon",
        component: () =>
            import ("../views/maps/polygon.vue"),
    },
    {
        path: "/stat/drivers",
        name: "statDrivers",
        component: () =>
            import ("../views/stat/index.vue"),
    },
    {
        path: "/news",
        name: "news",
        component: () =>
            import ("../views/news/index.vue"),
    },
    {
        path: "/news/create",
        name: "newsCreateForm",
        component: () =>
            import ("../views/news/new.vue"),
    },
    {
        path: "/news/:id",
        name: "newsEdit",
        component: () =>
            import ("../views/news/edit.vue"),
    },
    {
        path: "/recommendations",
        name: "recommendations",
        component: () =>
            import ("../views/recommendations/list.vue"),
    },
    {
        path: "/recommendations/new",
        name: "createRecommendation",
        component: () =>
            import ("../views/recommendations/new.vue"),
    },
    {
        path: "/recommendations/edit/:id",
        name: "editRecommendation",
        component: () =>
            import ("../views/recommendations/edit.vue"),
    },
    {
        path: "/payment",
        name: "payment",
        component: () =>
            import ("../views/payment/index.vue"),
    },
    {
        path: "/payment/edit/edit/:id",
        name: "editPayment",
        component: () =>
            import ("../views/payment/edit.vue"),
    },
    {
        path: "/reports/reports",
        name: "reportOrders",
        component: () =>
            import ("../views/reports/reports.vue"),
    },
    {
        path: "/calculation/rules",
        name: "calculationRules",
        component: () =>
            import ("../views/calculation/rules/index.vue"),
    },
    {
        path: "/calculation/shiftClose/index",
        name: "driverCalculation",
        component: () =>
            import ("../views/calculation/shiftClose/index.vue"),
    },
    {
        path: "/calculation/payments/index",
        name: "driverPayment",
        component: () =>
            import ("../views/calculation/payments/index.vue"),
    },
    {
        path: "/version",
        name: "version",
        component: () =>
            import ("../views/versions/index.vue"),
    },
    {
        path: '/settings',
        name: 'settings',
        component: () => import('../views/settings/index.vue')
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    const isPublic = to.matched.some((record) => record.meta.public);
    const isTokenSet = store.getters["auth/isLoggedIn"];
    if (!isPublic && !isTokenSet) {
        return next("/login");
    }
    return next();
});

export default router;
